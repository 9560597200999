<template>
  <div class="r-cover md:flex overflow-hidden md:overflow-auto bg-grey-500">
    <TourSlideout
      :section-color="sectionColour"
      :subheading="section.title.rendered"
      :title="group.title.rendered"
      :nav-label="`${group.title.rendered} group menu`"
    >
      <template v-slot:excerpt>
        <p class="description font-normal text-base mb-8">
          {{ formattedText }}
          <span v-if="formattedText.length > maxChars" class="text-blue cursor-pointer" @click="triggerReadMore(event)">
            {{ isReadMore ? 'Read Less' : 'Read More' }}
          </span>
        </p>
      </template>
      <template v-slot:links>
        <ul class="group-list mb-5 md:mb-0">
          <li
            :key="index"
            v-for="(post, index) in group.content"
            class="group-list__item font-normal cursor-pointer text-base"
            :class="[
              { 'is-active bg-white p-0': activePost.slug === post.post_name },
              `list-item-${index}`
            ]"
          >
            <button
              class="block w-full py-2 md:py-3 text-left text-xs md:text-base"
              :class="[activePost.slug === post.post_name ? 'border-secondary border-l-8 px-4 md:px-8' : 'px-6 md:px-10']"
              @click="setCurrentPost(post)"
            >
              {{ post.post_title }}
            </button>
            <ul
              v-if="isPanorama && getCurrentPanoChildren(post)"
              class="bg-grey-100"
              :class="{'hidden': activePost.slug !== post.post_name}"
            >
              <template
                v-for="(hotspot, index) in getCurrentPanoChildren(post)"
                :key="index"
              >
                <li v-if="hotspot && hotspot.entry">
                  <button
                    class="group-list__item group-list__item--child w-full flex items-center font-normal px-10 py-3 cursor-pointer text-sm md:text-base"
                    :class="{'is-active': activeOverlaySlug === hotspot.entry.post_name}"
                    @click="setHotspotCurrentPost(hotspot)"
                  >
                    <div class="flex justify-center w-8">
                      <img
                        :src="require(`@/assets/icon-hs-${hotspot.entry.post_type}.svg`)"
                        :alt="hotspot.entry.post_type"
                        role="presentation"
                        class="relative h-4 mr-2"
                      />
                    </div>
                    {{ hotspot.entry.post_title }}
                  </button>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </template>
    </TourSlideout>
    <div class="post-section r-cover md:w-9/12 opacity-0">
      <OverlayPost
        v-if="overlayActive"
        :post="activeOverlayPost"
        @closePostOverlay="closeOverlay"
      />
      <Post
        :post="activePost"
        :overlay-post="overlayActive"
        @hotSpotClick="handleHotspotClick"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { loadKrp } from '@/mixins'
import { mapGetters } from 'vuex'

import TourSlideout from '@/components/posts/TourSlideout.vue'
import Post from '@/components/posts/Post.vue'
import OverlayPost from '@/components/posts/OverlayPost.vue'

let tl = null

export default {
  name: 'Group',

  components: {
    TourSlideout,
    Post,
    OverlayPost,
  },

  mixins: [ loadKrp ],

  data() {
    return {
      currentPost: null,
      overlayActive: false,
      isReadMore: false,
      maxChars: window.screen.width < 968 ? 110 : 130
    }
  },

  computed: {
    ...mapGetters(['getGroupBySlug', 'getPostBySlug', 'getPanoChildren', 'getSectionBySlug']),
    activePost() {
      const { $route, getPostBySlug, group = {} } = this
      const { params = {} } = $route
      const groupContent = group.content ?? []
      const currentPost = groupContent.find(({post_name}) => post_name === params.content)
      const { post_type } = currentPost || {}
      return getPostBySlug(params.content, post_type)
    },
    activeOverlayPost() {
      return this.getPostBySlug(this.$route.params.hotspot) ?? {}
    },
    activeOverlaySlug() {
      return this.activeOverlayPost.slug ?? {}
    },
    group() {
      return this.getGroupBySlug(this.$route.params.group)
    },
    isPanorama() {
      return this.activePost.type === 'panorama'
    },
    section() {
      return this.getSectionBySlug(this.$route.params.section)
    },
    sectionColour() {
      return this.section.colour ?? '#ada2cf'
    },
    formattedText() {
      const description = this.group.description ?? ''
      if (!this.isReadMore && description.length > this.maxChars) {
        return this.truncate(description, this.maxChars)
      }

      return description
    }
  },

  watch: {
    $route(from, to) {
      this.$nextTick(() => {
        if (to.name !== 'group' && to.name !== 'hotspot') {
          this.loadTimeline()
          tl.play()
        }
      })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
    if (this.$route.params.hotspot) {
      this.overlayActive = true
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === 'group' || to.name === 'hotspot') {
      next()
    } else {
      this.animateOut(next)
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'group' || to.name === 'hotspot') {
      next()
    } else {
      this.animateOut(next)
    }
  },

  methods: {
    setCurrentPost(post) {
      this.overlayActive = false
      this.currentPost = post
      this.$router.push({
        name: 'group',
        params: { content: post.post_name }
      })
    },
    setHotspotCurrentPost(hotspot) {
      this.$router
        .push({
          name: 'hotspot',
          params: {
            content: this.activePost.slug,
            hotspot: hotspot.entry.post_name
          }
        })
      this.overlayActive = true
    },
    getCurrentPanoChildren(post) {
      return this.getPanoChildren(post.post_name)
    },
    closeOverlay() {
      this.overlayActive = false
      this.$router.push({
        name: 'group',
        params: { content: this.activePost.slug }
      })
    },
    handleHotspotClick() {
      this.overlayActive = true
    },
    loadTimeline() {
      tl = gsap.timeline({ paused: true })
      tl.fromTo('.section-title', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.post-section', { opacity: 0, x: 100 }, { opacity: 1, x: 0, delay: 0.2, duration: 1, ease: 'power1.inOut' }, 1)
      tl.fromTo('.heading', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.description', { opacity: 0, y: -50 }, { opacity: 1, y: 0, delay: 0.6, duration: 0.6, ease: 'power1.inOut' }, 1)
      const contentGroup = this.group.content ?? []
      contentGroup.forEach((item, index) => {
        const groupDelay = (index * 0.2)
        tl.fromTo(`.slideout-content-desktop .list-item-${index}`, { opacity: 0, x: -20 }, { opacity: 1, x: 0, delay: groupDelay, duration: 0.6, ease: 'power1.inOut' }, 2)
      })
    },
    animateOut(next) {
      this.$store.commit('toggleMenu', false)
      tl.timeScale(2.5).reverse()
      tl.eventCallback('onReverseComplete', () => {
        next()
      })
    },
    truncate(copy, limit) {
      const newCopy = []

      if (copy.length > limit) {
        copy.split(' ').reduce((acc, cur) => {
          if (acc + cur.length <= limit) {
            newCopy.push(cur)
          }

          return acc + cur.length
        }, 0)

        return `${newCopy.join(' ')} ...`
      }

      return copy
    },
    triggerReadMore(e) {
      this.isReadMore = !this.isReadMore
    }
  },

  beforeRouteEnter(to, from, next) {
    loadKrp(to, from, next)
  }
}
</script>

<style lang="scss" scoped>
  .group-list__item {
    button {
      &:hover {
        background-color: var(--color-secondary);
        ~ .rounded-full {
          background-color: var(--color-black)!important;
        }
      }
    }
  }

  .group-list__item--child {
    &.is-active {
      background-color: var(--color-secondary);
    }
    &:hover {
      background-color: var(--color-secondary);
    }
  }
</style>
